import React, { useEffect, useState } from 'react';
import {
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import { STORAGE_VARIABLES } from '@/constants';
import './index.scss';

function PerformChallenge() {
  const [allowAccess, setAllowAccess] = useState(false);
  useEffect(() => {
    const handlePerformChallenge = (performChallenge) => {
      const {
        acsUrl,
        creq,
        sessionData
      } = JSON.parse(performChallenge);

      if (!acsUrl || !creq) {
        window.close();
        return null;
      }

      const form = document.createElement('form');
      form.method = 'post';
      form.action = acsUrl;
      const inputFields = { creq ,threeDSSessionData:sessionData };
      Object.keys(inputFields).forEach(key => {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = inputFields[key];
        form.appendChild(hiddenField);
      });
      document.body.appendChild(form);
      form.submit();
      localStorage.setItem(STORAGE_VARIABLES.TRANS_CREATED, true);
      return true;
    };

    const performChallenge = localStorage.getItem('rdcp-challenge');
    localStorage.removeItem('rdcp-challenge');
    if (performChallenge) {
      setAllowAccess(true);
      handlePerformChallenge(performChallenge);
    } else {
      window.location = '/not-found';
    }
  }, [setAllowAccess]);

  const loadingIcon = (
    <Loading3QuartersOutlined
      style={{
        fontSize: 48,
      }}
      spin
    />
  );

  return (
    <div className='rdcp-perform-challenge-container'>
      {allowAccess && <Spin indicator={loadingIcon} />}
    </div>
  );
}

export default PerformChallenge;

import React, { useEffect } from 'react';
import {
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';

import RPButton from '@/components/RPButton';
import logoBlueIcon from '@/assets/images/logo-blue.svg';
import checkedIcon from '@/assets/images/payment-method/checked-thinner-icon.svg';
import { formatter } from '@/utils';

import './index.scss';

function PaymentCompleted({
  transactionData,
  paymentRef,
  embedMode = false,
}) {
  const {
    amount,
    createdAt,
  } = transactionData;

  const handleOnClickBtnDone = () => {
    const message = {
      channel: 'rdcp-payment-button',
      event: 'close-rdcp-payment-form',
    };
    console.log();
    window.parent.postMessage(message, document.referrer);
  };

  useEffect(() => {
    localStorage.removeItem(`pay${paymentRef.id}`);
  });

  const {
    logo,
    merchant,
  } = paymentRef;

  return (
    <div className='rp-payment-completed'>
      <div className='rp-payment-completed__logo-group'>
        <div className='rp-payment-completed__logo-item --main-merchant'>
          <img
            src={logoBlueIcon}
            alt="icon"
            width="100%"
            height="100%"
          />
        </div>
        {logo && merchant &&
          <div className='rp-payment-completed__logo-item --client-merchant'>
            <img
              src={logo?.url}
              alt="icon"
              width="100%"
              height="100%"
            />
            <p className="mb-0">{merchant?.name}</p>
          </div>
        }
      </div>
      <div className='rp-payment-completed__checked-icon'>
        <img
          src={checkedIcon}
          alt="icon"
          width="100%"
          height="100%"
        />
      </div>
      <div className='rp-payment-completed__main'>
        <div className='rp-payment-completed__main__title'>
          Payment Completed
        </div>
        <div className='rp-payment-completed__main__details'>
          <Row className='rp-payment-completed__main__details-container'>
            <Col flex="50%" className='rp-payment-completed__main__details__label'>
              Payment For
            </Col>
            <Col flex="50%">
              {paymentRef?.description}
            </Col>
          </Row>
          <Row className='rp-payment-completed__main__details-container'>
            <Col flex="50%" className='rp-payment-completed__main__details__label'>
              Payment ID
            </Col>
            <Col flex="50%" className='text-break'>
              {transactionData.id}
            </Col>
          </Row>
          <Row className='rp-payment-completed__main__details-container'>
            <Col flex="50%" className='rp-payment-completed__main__details__label'>
              Date
            </Col>
            <Col flex="50%">
              {formatter.parseDateTime(createdAt)}
            </Col>
          </Row>
          <Row className='rp-payment-completed__main__details-container'>
            <Col flex="50%" className='rp-payment-completed__main__details__label'>
              Amount
            </Col>
            <Col flex="50%">
              {formatter.formatCurrency(amount / 100)}
            </Col>
          </Row>
        </div>
      </div>
      {embedMode && <div className='btn-done-container'>
        <RPButton
          gradient
          className='w-100'
          onClick={handleOnClickBtnDone}
        >Done</RPButton>
      </div>
      }
    </div>
  );
}

PaymentCompleted.propTypes = {
  transactionData: PropTypes.object.isRequired,
  paymentRef: PropTypes.object.isRequired,
  embedMode: PropTypes.bool,
};

export default PaymentCompleted;

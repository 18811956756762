import http from '@/http';
import { paymentPagesApis } from '@/constants/api';
import {
  ROUTES,
} from '@/constants';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';
import { LIST_ERRORS } from './errors';

export const getPaymentPagesDetails = async ({ id }) => {
  try {
    const res = await http.get(`${paymentPagesApis.base}/${id}`);

    if (res?.status === 200) {
      const { data } = parse.camelize(res);
      return data;
    }

    return null;
  } catch (error) {
    if (error.response.status === 404) {
      window.location.href = ROUTES.PAYMENT_PAGE.NOT_FOUND;
      return null;
    }
    messageNotifyUtil.setErrorMessage({
      title: error.response.data.code,
      description: error.response.data.message,
    });
    return null;
  }
};


export async function createOrder(payload) {
  try {
    const res = await http.post(paymentPagesApis.createOrder, parse.snakelize(payload));

    if (res?.status === 200) {
      const { data } = parse.camelize(res);
      return {
        success: true,
        data,
      };
    }

    return {
      success: false,
      data: null,
    };
  } catch (error) {
    if (error.response?.status === 400) {
      const { reason } = error.response.data;
      let { message, code } = error.response.data;
      if (code === 'INVALID_ARGUMENT' && LIST_ERRORS[reason] !== undefined) {
        message = LIST_ERRORS[reason];
        code = 'Invalid argument';
      }
      messageNotifyUtil.setErrorMessage({
        title: code,
        description: message,
      });
    } else {
      messageNotifyUtil.setErrorMessage({
        title: 'UNKONW_ERROR',
        description: 'Something went wrong. Please try again later!',
      });
    }

    return {
      success: false,
      data: null,
    };
  }
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Radio,
  Spin,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';
import { uniqueId } from 'lodash';
import { Icon } from '@iconify/react';
import { generatePath } from 'react-router-dom';

import { ENV, PAYMENT_METHODS, ROUTES, STORAGE_VARIABLES, TRANSACTION_STATUSES } from '@/constants';
import { parse, windowUtil } from '@/utils';
import { transactionService } from '@/services';
import HeadingPayMethod from '@/components/PaymentMethod/Methods/Heading';
import chekedIcon from '@/assets/images/payment-method/checked-icon.svg';
import netbankingDefaultIcon from '@/assets/images/payment-method/netbanking-icon.svg';

import './index.scss';

const PAY_SUCCEED = 'succeed';
const PAY_FAILED = 'failed';

function NetBanking({
  data,
  form,
  onBack,
  setData,
  phoneNumber,
  toggleRunPay,
  setToggleRunPay,
  embedMode = false,
  handlePaymentUnknownError,
  handleCreateTransaction,
  currentMode,
}) {
  // handle variable
  const [bankData, setBankData] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [externalWindow, setExternalWindow] = useState(null);
  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const [netbankingPayLink, setNetbankingPayLink] = useState(null);
  const [processFinalStep, setProcessFinalStep] = useState(false);

  // handle function

  const getSupportedBank = async () => {
    const { success, data: dataRes } = await transactionService.getSupportedBank(currentMode);
    if (success) {
      setBankData(dataRes);
    }
    setTimeout(() => {
      setLoadingPage(false);
    }, 600);
  };

  const onPay = async () => {
    setToggleRunPay(false);
    localStorage.setItem(STORAGE_VARIABLES.PAY_PROCESSING, true);
    const newWindow = windowUtil.openExternalWindow(ROUTES.PAYMENTS.PAYMENT_PROCESSING);
    setExternalWindow(newWindow);

    // call api create transaction => return url

    const { paymentMethod, paymentInformation } = data;
    const { success, data: dataRes } = await handleCreateTransaction({ paymentMethod, paymentInformation });
    if (success) {
      setCurrentTransactionId(dataRes.id);
      sessionStorage.removeItem(`pay${dataRes.order.refId}`);
      const { nextAction } = dataRes;
      const { action, paymentInfo } = nextAction;
      if (action === 'PAYMENT_INFO') {
        const { paymentUrl } = paymentInfo;
        setNetbankingPayLink(paymentUrl);
      }
    } else {
      // handler error
      setTimeout(() => {
        newWindow.close();
        setExternalWindow(null);
         handlePaymentUnknownError({ openErrorPage : true });
      }, 3000);
    }
  };

  const handleSearchBankName = (e) => {
    const { value } = e.target;

    if (value) {
      const newBanks = bankData.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

      setBankData(newBanks);
    } else {
      getSupportedBank();
    }
  };

  const renderExtra = () => {
    if (!phoneNumber) {
      return null;
    }

    return (
      <>
        <UserOutlined />
        <span>{parse.phoneNumberInternational(phoneNumber)}</span>
      </>
    );
  };

  const handleOnChangeBank = (e) => {
    const payMethod = 'netbanking';
    setData({
      ...data,
      paymentMethod: PAYMENT_METHODS[payMethod]?.value,
      paymentInformation: {
        bankCode: e.target.value,
      }
    });
  };

  // handle useEffect

  useEffect(() => {
    getSupportedBank();
    form.resetFields(['bankCode']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toggleRunPay) {
      console.log('Paying...');
      onPay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRunPay]);

  useEffect(() => {
    if (externalWindow) {
      let timeout;
      if (!externalWindow.closed) {
        console.log(`Window opened. Closing after ${ENV.EXPIRE_TIME_PAY_VIA_NETBANKING / 60 / 1000}min`);
        timeout = setTimeout((newWindow) => {
          console.log('running timeout open window');
          newWindow.close();
          setProcessFinalStep(true);
        }, ENV.EXPIRE_TIME_PAY_VIA_NETBANKING, externalWindow);
      }

      const timer = setInterval(() => {
        if (externalWindow.closed) {
          console.log('Window closed');
          clearTimeout(timeout);
          clearInterval(timer);
          setProcessFinalStep(true);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalWindow]);

  useEffect(() => {
    if (netbankingPayLink && externalWindow) {
      console.log('Start Pay via Netbanking link');
      localStorage.setItem(STORAGE_VARIABLES.TRANS_CREATED, true);
      externalWindow.location.href = netbankingPayLink;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netbankingPayLink]);

  useEffect(() => {
    const handlePaymentFinalStep = async () => {
      localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
      localStorage.removeItem(STORAGE_VARIABLES.TRANS_CREATED);
      if (!currentTransactionId) {
        setExternalWindow(null);
         handlePaymentUnknownError({ openErrorPage : true });
      }

      if (embedMode) {
        localStorage.setItem('rp-payment-btn-transaction', 'created');
      }
      const paymentResult = localStorage.getItem(`trans${currentTransactionId}`);
      // Handle Payment Succeed/Failed from BE response
      if (paymentResult === PAY_SUCCEED || paymentResult === PAY_FAILED) {
        console.log(`Pay ${paymentResult}`);
        window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
      } else {  // Handle timeout of payment reached
        console.log('Pay time out OR user close window');
        const {
          success,
          data: responseData
        } = await transactionService.getTransaction(currentTransactionId);
        const canRedirectToTransactionDetail = success &&
          (
            responseData?.status === TRANSACTION_STATUSES.captured ||
            responseData?.status === TRANSACTION_STATUSES.failed
          );

        if (canRedirectToTransactionDetail) {
          window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
        } else {
          localStorage.removeItem('rp-payment-btn-transaction');
           handlePaymentUnknownError({ openErrorPage : true });
        }
      }
    };

    if (processFinalStep) {
      handlePaymentFinalStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processFinalStep]);

  return (
    <div className={loadingPage ? 'text-center' : ''}>
      {loadingPage && <Spin />}
      {!loadingPage &&
        <>
          <HeadingPayMethod
            title='Netbanking'
            onBack={onBack}
            extra={renderExtra()}
          />
          <div className='rp-netbanking'>
            <div className='rp-netbanking__search'>
              <Input
                size="large"
                bordered={false}
                placeholder="Type a bank name"
                onChange={handleSearchBankName}
                prefix={<Icon icon='bx:search' style={{ fontSize: '18px' }} />}
              />
            </div>
            <div className='rp-netbanking__text'>Popular Banks</div>
            <div className='rp-netbanking__list'>
              <Form.Item
                name='bankCode'
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Please select one' },
                ]}
                onChange={handleOnChangeBank}
              >
                <Radio.Group
                  className='w-100'
                >
                  {bankData.length > 0 && bankData.map(option => (
                    <Radio.Button
                      key={uniqueId()}
                      value={option.code}
                      className='rp-netbanking__list-item__selector'
                    >
                      <div className='rp-netbanking__list-item__label-container'>
                        <div className='rp-netbanking__list-item__logo'>
                          <img
                          src={option.icon ? option.icon  : netbankingDefaultIcon}
                            alt={option.name}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className='rp-netbanking__list-item__name'>
                          {option.name}
                        </div>
                        <div className='rp-netbanking__list-item__checked'>
                          <img
                            src={chekedIcon}
                            alt='Checked Icon'
                          />
                        </div>
                      </div>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </>
      }
    </div>
  );
}

NetBanking.propTypes = {
  data: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  embedMode: PropTypes.bool.isRequired,
  toggleRunPay: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setToggleRunPay: PropTypes.func.isRequired,
  handleCreateTransaction: PropTypes.func.isRequired,
  handlePaymentUnknownError: PropTypes.func.isRequired,
  currentMode: PropTypes.oneOf(['Live', 'Test']).isRequired,
};

export default NetBanking;

export const paymentPagesApis = {
  base: '/payment-pages',
  createOrder: '/orders',
  transactions: '/transactions',
};

export const transactionAPIs = {
  base: '/transactions',
  get: '/transactions/:id',
  supportedBanks: '/transactions/supported-banks',
  supportedWallet: '/transactions/supported-wallets'
};


export const paymentButtonsApis = {
  base: '/payment-buttons',
};


export const paymentLinkApis = {
  base: '/payment-links',
};

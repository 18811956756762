import React from 'react';
import PropsType from 'prop-types';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import './index.scss';

function RPPhoneNumber({
  value,
  onChange,
  required,
  ...props
}) {
  const handleOnChange = (phone) => {
    if (onChange !== undefined) {
      onChange(phone);
    }
  };

  return (
    <div className={`rp-phone-number ${required ? '--required' : ''}`}>
      <PhoneInput
        country="in"
        enableSearch
        searchPlaceholder='Search'
        specialLabel='Phone Number'
        inputClass="input-phone"
        inputProps={{
          name: 'phone_number',
          required: true,
        }}
        countryCodeEditable={false}
        {...props}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
}

RPPhoneNumber.propTypes = {
  value: PropsType.string,
  onChange: PropsType.func,
  required: PropsType.bool
};

export default RPPhoneNumber;

import creditDebitCardIcon from '@/assets/images/payment-method/credit-debit-card-icon.svg';
import creditDebitCardActiveIcon from '@/assets/images/payment-method/credit-debit-card-active-icon.svg';
import upiQrCodeIcon from '@/assets/images/payment-method/upi-qr-code-icon.svg';
import upiQrCodeActiveIcon from '@/assets/images/payment-method/upi-qr-code-active-icon.svg';
import netbankingIcon from '@/assets/images/payment-method/netbanking-icon.svg';
import netbankingActiveIcon from '@/assets/images/payment-method/netbanking-active-icon.svg';
import walletIcon from '@/assets/images/payment-method/wallet-icon.svg';
import walletActiveIcon from '@/assets/images/payment-method/wallet-active-icon.svg';
import prepaidCardIcon from '@/assets/images/payment-method/prepaid-card-icon.svg';
import prepaidCardActiveIcon from '@/assets/images/payment-method/prepaid-card-active-icon.svg';

export const PAYMENT_METHODS_AVAILABLE = {
  CREDIT_DEBIT: 'credit_debit',
  UPI: 'upi',
  QR : 'qr',
  NETBANKING: 'netbanking',
  WALLET: 'wallet',
  PREPAID: 'prepaid',
};

export const PAYMENT_METHOD_OPTIONS = [
  {
    name: 'Credit / Debit Options',
    icon: creditDebitCardIcon,
    iconActive: creditDebitCardActiveIcon,
    value: PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT,
    description: 'Description of payment method',
  },
  {
    name: 'UPI / QR',
    icon: upiQrCodeIcon,
    iconActive: upiQrCodeActiveIcon,
    value: PAYMENT_METHODS_AVAILABLE.UPI,
    description: 'Description of payment method',
  },
  {
    name: 'Netbanking',
    icon: netbankingIcon,
    iconActive: netbankingActiveIcon,
    value: PAYMENT_METHODS_AVAILABLE.NETBANKING,
    description: 'Description of payment method',
  },
  {
    name: 'Wallet',
    icon: walletIcon,
    iconActive: walletActiveIcon,
    value: PAYMENT_METHODS_AVAILABLE.WALLET,
    description: 'Description of payment method',
  },
  {
    name: 'Gift Card/ Prepaid Card',
    icon: prepaidCardIcon,
    iconActive: prepaidCardActiveIcon,
    value: PAYMENT_METHODS_AVAILABLE.PREPAID,
    description: 'Description of payment method',
  },
];

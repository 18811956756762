import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Modal,
  Spin,
} from 'antd';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { formatter } from '@/utils';
import RPButton from '@/components/RPButton';
import PayContent from '@/components/PaymentMethod/PaymentCard/PayContent';

import CreditDebitCardContainer from '@/components/PaymentMethod/Methods/CreditDebitCard/Container';
import UPIQRCode from '@/components/PaymentMethod/Methods/UPIQRCode';
import NetBanking from '@/components/PaymentMethod/Methods/NetBanking';
import Wallet from '@/components/PaymentMethod/Methods/Wallet';
import PrepaidCard from '@/components/PaymentMethod/Methods/PrepaidCard';
import HighlightTestMode from '@/components/shared/HighlightTestMode';
import {
  PAYMENT_METHODS_AVAILABLE
} from '@/constants/PaymentMethodOptions';
import { RDCP_MODES } from '@/constants';
import logoBlueIcon from '@/assets/images/logo-blue.svg';
import redXIcon from '@/assets/images/payment-method/red-x-icon.svg';
import { usePayModalConfirmConfig } from '@/hooks';
import { validateUpiId } from '@/utils/validate';
import { selectPaymentButtons } from '@/store/paymentButton/paymentButtonSelector';

import './index.scss';

function PaymentCard({
  email,
  amount,
  orderId,
  onFinish,
  className,
  phoneNumber,
  merchantName,
  merchantLogo,
  backToOrderForm,
  embedMode = false,
  currentMode,
}) {
  const { id: paymentRefId } = useParams();
  const [formInstance] = Form.useForm();
  const [proceeding, setProceeding] = useState(false);
  const [toggleRunPay, setToggleRunPay] = useState(false);
  const [data, setData] = useState({
    paymentMethod: null,
    paymentInformation: {},
  });
  const [selectedPayMethod, setSelectPayMethod] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(false);
  const [somethingWrong, setSomethingWrong] = useState(false);
  const [showBackIcon, setShowBackIcon] = useState(true);
  const { paymentChannel } = useSelector(selectPaymentButtons);
  const payModalConfirmConfig = usePayModalConfirmConfig({ paymentChannel });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTimeout(() => setLoadingPage(false),
      600
    );
  });

  useEffect(() => {
    const allFiledsHaveValidValue = (formHandler) => {
      const fieldsValue = formHandler.getFieldsValue();
      const fieldsValueKeys = Object.keys(fieldsValue);
      if (fieldsValueKeys.length > 0) {
        return fieldsValueKeys.every(key => {
          if (key === 'upiId') {
            return validateUpiId(fieldsValue[key]);
          }
          return !!fieldsValue[key];
        });
      }
      if(data.paymentMethod === PAYMENT_METHODS_AVAILABLE.QR){
        return true;
      }
      return false;
    };

    const isFormValid =
      allFiledsHaveValidValue(formInstance) &&
      formInstance.getFieldsError().every(({ errors }) => errors.length === 0);
    setEnableBtnSubmit(isFormValid && data.paymentMethod);
  }, [data, formInstance]);

  useEffect(() => {
    if (selectedPayMethod) {
      setShowBackIcon(false);
    } else {
      setShowBackIcon(true);
    }
  }, [selectedPayMethod]);

  const handlePaymentUnknownError = ({ openErrorPage = true }) => {
    setProceeding(false);
    setSomethingWrong(openErrorPage);
  };

  const paymentCardKlass = className ? `rp-payment-card-container ${className}` : 'rp-payment-card-container';

  const handleOnFinish = async () => {
    setProceeding(true);
    setToggleRunPay(true);
  };

  const onBack = () => {
    setSelectPayMethod(null);
    setToggleRunPay(false);
    setData({
      ...data,
      paymentMethod: null,
      paymentInformation: {}
    });
  };

  const renderPaymentMethod = () => {
    let child = null;
    switch (selectedPayMethod) {
      case PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT:
        child = <CreditDebitCardContainer
          data={data}
          form={formInstance}
          setData={setData}
          onBack={onBack}
          phoneNumber={phoneNumber}
          toggleRunPay={toggleRunPay}
          setToggleRunPay={setToggleRunPay}
          handlePaymentUnknownError={handlePaymentUnknownError}
          handleCreateTransaction={onFinish}
          embedMode={embedMode}
        />;
        break;
      case PAYMENT_METHODS_AVAILABLE.UPI:
        child = <UPIQRCode
          data={data}
          form={formInstance}
          setData={setData}
          onBack={onBack}
          phoneNumber={phoneNumber}
          toggleRunPay={toggleRunPay}
          setToggleRunPay={setToggleRunPay}
          handlePaymentUnknownError={handlePaymentUnknownError}
          handleCreateTransaction={onFinish}
          embedMode={embedMode}
        />;
        break;
      case PAYMENT_METHODS_AVAILABLE.NETBANKING:
        child = <NetBanking
          data={data}
          form={formInstance}
          setData={setData}
          onBack={onBack}
          embedMode={embedMode}
          phoneNumber={phoneNumber}
          toggleRunPay={toggleRunPay}
          setToggleRunPay={setToggleRunPay}
          handleCreateTransaction={onFinish}
          handlePaymentUnknownError={handlePaymentUnknownError}
          currentMode={currentMode}
        />;
        break;
      case PAYMENT_METHODS_AVAILABLE.WALLET:
        child = <Wallet
        data={data}
        form={formInstance}
        setData={setData}
        onBack={onBack}
        embedMode={embedMode}
        phoneNumber={phoneNumber}
        toggleRunPay={toggleRunPay}
        setToggleRunPay={setToggleRunPay}
        handleCreateTransaction={onFinish}
        handlePaymentUnknownError={handlePaymentUnknownError}
        
        />;
        break;
      case PAYMENT_METHODS_AVAILABLE.PREPAID:
        child = <PrepaidCard
          data={data}
          form={formInstance}
          setData={setData}
          onBack={onBack}
          embedMode={embedMode}
        />;
        break;
      default:
        break;
    }
    const proceedingClass = proceeding ? '--proceeding' : '';
    return (
      <div className={`rp-payment-mask ${proceedingClass}`}>
        <div className='proceeding-mask' />
        {child}
      </div>
    );
  };

  const handleOnRetry = () => {
    setSelectPayMethod(null);
    setProceeding(false);
    setData({
      paymentMethod: null,
      paymentInformation: {},
    });
    formInstance.resetFields(['cardHolderName', 'number', 'month', 'year', 'cvv']);
    setSomethingWrong(false);
    const payload = {
      id: orderId,
      amount: amount * 100,
      email,
      contactNumber: phoneNumber,
    };
    sessionStorage.setItem(`pay${paymentRefId}`, JSON.stringify(payload));
  };

  const handleBackToOrderForm = () => {
    backToOrderForm();
    localStorage.removeItem('rp-order-data');
  };

  const renderFooter = () => (
    <div className='rp-payment-card__footer'>
      {amount && !somethingWrong &&
        <RPButton
          gradient
          center
          loading={proceeding}
          disabled={!enableBtnSubmit || proceeding}
          htmlType='submit'
        >
          {`Pay ${formatter.formatCurrency(amount)}`}
        </RPButton>
      }
      {somethingWrong &&
        <RPButton
          center
          gradient
          onClick={handleOnRetry}
        >
          Retry
        </RPButton>
      }
    </div>
  );

  const renderTestMode = () => currentMode === RDCP_MODES.TEST_MODE && <HighlightTestMode />;

  return (
    <div className={`${paymentCardKlass} position-relative`}>
      {renderTestMode()}
      <Form
        form={formInstance}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={data}
        onFinish={handleOnFinish}
        autoComplete="off"
        scrollToFirstError
      >
        <div className={`rp-payment-card__heading${embedMode ? ' --embed-mode' : ''}`}>
          <div className='rp-payment-card__heading__actions'>
            {embedMode &&
              <Button
                type='link'
                onClick={() => Modal.confirm(payModalConfirmConfig)}
                className='button-close-x'
              >
                <CloseOutlined style={{ fontSize: 16 }} />
              </Button>
            }
          </div>
          <div className='rp-payment-card__heading__logo-group'>
            <div className='rp-payment-card__heading__logo-item --main-merchant'>
              <img
                src={logoBlueIcon}
                alt="icon"
                width="100%"
                height="100%"
              />
            </div>
            <div className='rp-payment-card__heading__logo-item --client-merchant'>
              {merchantLogo && Object.keys(merchantLogo).length > 0 &&
                <div className='merchant-logo'>
                  <img
                    src={merchantLogo?.url}
                    alt={merchantLogo?.name}
                    height="100%"
                  />
                </div>
              }
              {!!merchantName && <p className="mb-0">{merchantName}</p>}
            </div>
          </div>

          {!loadingPage && amount && <div className='rp-payment-card__heading__payment-info'>
            {!!orderId &&
              <div
                className={`rp-payment-card__heading__payment-info__pay-id${showBackIcon ? ' --has-back-icon' : ''}`}
              >
                {showBackIcon &&
                  <Button
                    type='link'
                    onClick={handleBackToOrderForm}
                    className='button-back'
                  >
                    <LeftOutlined style={{ fontSize: 16 }} />
                  </Button>
                }
                Payment {orderId}
              </div>
            }
            {!!amount &&
              <>
                <div className='rp-payment-card__heading__payment-info__payment-desc'>Amount Payable Now</div>
                <div className='rp-payment-card__heading__payment-info__payment-amount'>
                  {formatter.formatCurrency(amount)}
                </div>
              </>
            }
          </div>
          }
        </div>
        <div className={`rp-payment-card__body${loadingPage ? ' text-center' : ''}`}>
          {loadingPage && <Spin />}
          {!loadingPage && !selectedPayMethod &&
            <PayContent
              data={data}
              email={email}
              setData={setData}
              phoneNumber={phoneNumber}
              setSelectPayMethod={setSelectPayMethod}
            />
          }
          {!somethingWrong && renderPaymentMethod()}
          {somethingWrong &&
            <div className='rp-payment-card__body'>
              <div className="rp-payment-card-wrapper">
                <div className="rp-payment-card__body__fail-container">
                  <div className="rp-payment-card__body__fail-icon">
                    <img
                      src={redXIcon}
                      alt="icon"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="rp-payment-card__body__fail__content">
                    <p>Your payment didn&rsquo;t go through as it was declined by the bank.</p>
                    <p>Try another payment method or contact your bank.</p>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {renderFooter()}
      </Form>
    </div>
  );
}

PaymentCard.propTypes = {
  embedMode: PropTypes.bool,
  className: PropTypes.string,
  merchantLogo: PropTypes.object,
  merchantName: PropTypes.string,
  email: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  onFinish: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  backToOrderForm: PropTypes.func.isRequired,
  currentMode: PropTypes.oneOf(['Live', 'Test']).isRequired,
};

export default PaymentCard;

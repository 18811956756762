/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreditDebitCard from '@/components/PaymentMethod/Methods/CreditDebitCard';

import './index.scss';
import { windowUtil } from '@/utils';
import { ENV, ROUTES, STORAGE_VARIABLES, TRANSACTION_ACTIONS, TRANSACTION_STATUSES } from '@/constants';
import { transactionService } from '@/services';
import { generatePath } from 'react-router-dom';

const PAY_SUCCEED = 'succeed';
const PAY_FAILED = 'failed';
function CreditDebitCardContainer({
  data,
  form,
  onBack,
  setData,
  phoneNumber,
  toggleRunPay,
  setToggleRunPay,
  embedMode = false,
  handlePaymentUnknownError,
  handleCreateTransaction,
}) {
  // handle state
  const [iframeCode, setIframeCode] = useState('');
  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const [triggerGetTransaction, setTriggerGetTransaction] = useState(false);
  const [externalWindow, setExternalWindow] = useState(null);
  const [processFinalStep, setProcessFinalStep] = useState(false);
  const [formHasErrors, setFormHasErrors] = useState(false);

  // handle function
  const handlePerform3Ds = (perform3Ds) => {
    setIframeCode(perform3Ds);

    // Initiate 3DS Method function
    setTimeout(() => {
      const tdsMmethodTgtFrame = document.getElementById('tdsMmethodForm');
      if (tdsMmethodTgtFrame) {
        tdsMmethodTgtFrame.submit();
        setTriggerGetTransaction(true);
        console.log('Finish Perform3DS');
      }
    }, 1000);
  };

  const handlePerformChallenge = (performChallenge, externalCurrentWindow) => {
    localStorage.setItem('rdcp-challenge', JSON.stringify(performChallenge));

    if (externalCurrentWindow) {
      externalCurrentWindow.location.href = ROUTES.PAYMENTS.PERFORM_CHALLENGE_VERIFY;
      console.log('PerformChallenge verifying');
    } else {
       handlePaymentUnknownError({ openErrorPage : true });
    }
  };

  const handlePaymentViaPaymentUrlFromServer = (paymentUrl, externalCurrentWindow) => {
    if (externalCurrentWindow) {
      externalCurrentWindow.location.href = paymentUrl;
      localStorage.setItem(STORAGE_VARIABLES.TRANS_CREATED, true);
      console.log('Running payment via payment url');
    } else {
       handlePaymentUnknownError({ openErrorPage : true });
    }
  };

  const onPay = async () => {
    setToggleRunPay(false);
    localStorage.setItem(STORAGE_VARIABLES.PAY_PROCESSING, true);
    const newWindow = windowUtil.openExternalWindow(ROUTES.PAYMENTS.PAYMENT_PROCESSING);
    setExternalWindow(newWindow);

    const { paymentMethod, paymentInformation } = data;
    // create transaction
    const {
      success,
      reason,
      data: responseData,
      errors: responseErrors,
    } = await handleCreateTransaction({ paymentMethod, paymentInformation });
    if (success) {
      console.log('Created Transaction');
      setFormHasErrors(false);
      setProcessFinalStep(false);
      setCurrentTransactionId(responseData.id);
      sessionStorage.removeItem(`pay${responseData.order.refId}`);

      const { nextAction } = responseData;
      if (nextAction) {
        const {
          action,
          perform3Ds,
          performChallenge,
          paymentInfo,
        } = nextAction;
        if (action === TRANSACTION_ACTIONS.perform3DS && !!perform3Ds) {
          console.log('Start Perform3DS');
          handlePerform3Ds(perform3Ds);
        }
        if (action === TRANSACTION_ACTIONS.performChallenge && !!performChallenge) {
          console.log('Start performChallenge');
          handlePerformChallenge(performChallenge, newWindow);
        }

        if (action === TRANSACTION_ACTIONS.paymentInfo && !!paymentInfo?.paymentUrl) {
          console.log('Start payment via url from server');
          handlePaymentViaPaymentUrlFromServer(paymentInfo.paymentUrl, newWindow);
        }
      } else {
        console.log('Do not have Next Action');
        setTriggerGetTransaction(true);
      }
    } else if (reason === 'INVALID_PAYMENT_CARD') {
      const fieldsError = Object.keys(responseErrors).map(key => (
        {
          name: key,
          errors: [responseErrors[key]]
        }
      ));
      form.setFields(fieldsError);
      setFormHasErrors(true);
      newWindow.close();
      setExternalWindow(null);
      localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
    } else {
      setTimeout(() => {
        newWindow.close();
        setExternalWindow(null);
        handlePaymentUnknownError(true);
        localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
      }, 2000);
    }
  };

  // handle useEffect
  useEffect(() => {
    if (toggleRunPay) {
      console.log('Paying...');
      onPay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRunPay]);

  useEffect(() => {
    const handleGetTransaction = async () => {
      if (!currentTransactionId) {
         handlePaymentUnknownError({ openErrorPage : true });
        return;
      }

      const {
        success,
        data: responseData
      } = await transactionService.getTransaction(currentTransactionId);

      const {
        status,
        nextAction,
      } = responseData;

      if (!success) {
         handlePaymentUnknownError({ openErrorPage : true });
        return;
      }

      if (status === TRANSACTION_STATUSES.captured || status === TRANSACTION_STATUSES.failed) {
        setTriggerGetTransaction(false);
        localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
        externalWindow.close();
        window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
      }

      if (
        status === TRANSACTION_STATUSES.processing &&
        nextAction?.action === TRANSACTION_ACTIONS.performChallenge &&
        !!nextAction?.performChallenge
      ) {
        setTriggerGetTransaction(false);
        console.log('PerformChallenge after finish Perform3DS');
        handlePerformChallenge(nextAction?.performChallenge, externalWindow);
      }
    };

    if (triggerGetTransaction) {
      console.log('Get Transaction second');
      const interval = setInterval(() => {
        handleGetTransaction();
      }, 1000);

      return () => clearInterval(interval);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetTransaction]);

  useEffect(() => {
    if (externalWindow) {
      let timeout;
      if (!externalWindow.closed) {
        console.log(`Window opened. Closing after ${ENV.EXPIRE_TIME_PAY_VIA_CARD / 60 / 1000}min`);
        timeout = setTimeout((newWindow) => {
          console.log('running auto close window');
          newWindow.close();
          setProcessFinalStep(true);
        }, ENV.EXPIRE_TIME_PAY_VIA_CARD, externalWindow);
      }

      const timer = setInterval(() => {
        if (externalWindow.closed) {
          console.log('window closed');
          clearTimeout(timeout);
          clearInterval(timer);
          setProcessFinalStep(true);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalWindow]);

  useEffect(() => {
    const handlePaymentFinalStep = async () => {
      localStorage.removeItem(STORAGE_VARIABLES.PAY_PROCESSING);
      localStorage.removeItem(STORAGE_VARIABLES.TRANS_CREATED);
      if (!currentTransactionId) {
        setExternalWindow(null);
         handlePaymentUnknownError({ openErrorPage : true });
      }

      if (embedMode) {
        localStorage.setItem('rp-payment-btn-transaction', 'created');
      }
      const paymentResult = localStorage.getItem(`trans${currentTransactionId}`);
      // Handle Payment Succeed/Failed from BE response
      if (paymentResult === PAY_SUCCEED || paymentResult === PAY_FAILED) {
        console.log(`Pay ${paymentResult}`);
        window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
      } else {  // Handle timeout of payment reached
        console.log('Pay time out OR user close window');
        const {
          success,
          data: responseData
        } = await transactionService.getTransaction(currentTransactionId);
        const canRedirectToTransactionDetail = success &&
          (
            responseData?.status === TRANSACTION_STATUSES.captured ||
            responseData?.status === TRANSACTION_STATUSES.failed
          );

        if (canRedirectToTransactionDetail) {
          window.location = generatePath(ROUTES.TRANSACTION.DETAIL, { id: currentTransactionId });
        } else {
          localStorage.removeItem('rp-payment-btn-transaction');
           handlePaymentUnknownError({ openErrorPage : true });
        }
      }
    };

    if (processFinalStep) {
      !formHasErrors ? handlePaymentFinalStep() : handlePaymentUnknownError({ openErrorPage: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processFinalStep]);

  return (
    <>
      <CreditDebitCard
        data={data}
        form={form}
        setData={setData}
        onBack={onBack}
        phoneNumber={phoneNumber}
      />
      {iframeCode.length > 0 && (
        <div
          dangerouslySetInnerHTML={{ __html: iframeCode }}
          className="iframe"
        />
      )}
    </>
  );
}

CreditDebitCardContainer.propTypes = {
  embedMode: PropTypes.bool,
  data: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  toggleRunPay: PropTypes.bool.isRequired,
  setToggleRunPay: PropTypes.func.isRequired,
  handlePaymentUnknownError: PropTypes.func.isRequired,
  handleCreateTransaction: PropTypes.func.isRequired,
};

export default CreditDebitCardContainer;
